import { PRODUCTDETAILTRANSFER_GET, PRODUCTDETAILTRANSFER_STEP, PRODUCTTRANSFER_TITLE } from '../../types'

const initialState = {
    productTransferDetailData: [],
    steps: [],
    stepsLoading: true,
    loading: true,
    titleLoading: true,
    titleData: ""
}

function ProductTransferReducer(state = initialState, action) {

    switch (action.type) {

        case PRODUCTDETAILTRANSFER_GET:
            return {
                ...state,
                productTransferDetailData: action.payload,
                loading: false

            }

        case PRODUCTDETAILTRANSFER_STEP:
            return {
                ...state,
                steps: action.payload,
                stepsLoading: false
            }

        case PRODUCTTRANSFER_TITLE:
            return {
                ...state,
                titleData: action.payload,
                titleLoading: false
            }

        default:
            return state
    }

}

export default ProductTransferReducer;