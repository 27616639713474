import React from 'react'
import "../Assets/Css/loading.css"

export default function Loading() {
    return (
        <div className="loadingFullPageParent">
            <div className="loader"></div>
        </div>
    )
}
