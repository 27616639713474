import { ADDPRODUCT_GET } from '../../types'

const initialState = {
    addProductData: [],
    loadingAddProduct: true,
    click:false
}

function AddProductReducer(state = initialState, action) {

    switch (action.type) {

        case ADDPRODUCT_GET:
            return {
                ...state,
                addProductData: action.payload,
                loadingAddProduct: false,
                click:true

            }
        default:
            return state
    }

}

export default AddProductReducer;