import { PROFILE_GET } from '../../types'

const initialState = {
    profileData: [],
    loadingData: true
}

function ProfileReducer(state = initialState, action) {

    switch (action.type) {

        case PROFILE_GET:
            return {
                ...state,
                profileData: action.payload,
                loadingData: false

            }
        default:
            return state
    }

}

export default ProfileReducer;