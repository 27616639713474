import { UPDATE_CONTACT_REQUEST_SENT, CONTACT_SET } from '../../types'

const initialState = {
    contactSetData: [],
    loadingSetData: false
}

function ContactSetReducer(state = initialState, action) {

    switch (action.type) {

        case CONTACT_SET:
            return {
                ...state,
                contactSetData: action.payload,
                loadingSetData: false

            }
        case UPDATE_CONTACT_REQUEST_SENT:
            return {
                ...state,
                contactSetData: "",
                loadingSetData: true

            }
        default:
            return state
    }

}

export default ContactSetReducer;