import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { AppsAction } from "../Actions/AppAction"
import { LanguagesAction } from "../Actions/LanguageAction"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivateRoute from "../../../Helpers/privateRoute"
import Loading from '../../../Helpers/Loading'

const Login = React.lazy(() => import('../../Login/Component/Login'))
const Dashboard = React.lazy(() => import('../../Dashboard/Component/Dashboard'))
const AddProduct = React.lazy(() => import('../../AddProduct/Component/AddProduct'))
const ListProductTransfer = React.lazy(() => import('../../ListProductTransfer/Component/ListProductTransfer'))
const ProductTransfer = React.lazy(() => import('../../ProductTransfer/Component/ProductTransfer'))
const Reservation = React.lazy(() => import('../../Reservation/Component/Reservation'))
const ReservationDetail = React.lazy(() => import('../../Reservation/Component/ReservationDetail'))
class App extends Component {


    componentDidMount() {

        if (localStorage.getItem("guid")) {
            this.props.AppsAction(localStorage.getItem("guid"));
        } else {
            this.props.AppsAction();
        }
        this.props.LanguagesAction();

    }


    render() {

        const { loading } = this.props.languageData;

        return (
            <Suspense fallback={<Loading />}>
                <Router>
                    {loading ? "" :
                        <div>
                           
                            <Switch>
                                <Route path="/" exact strict component={Login} />
                               
                                <PrivateRoute path="/Dashboard" component={Dashboard} />
                                <PrivateRoute path="/AddProduct" component={AddProduct} />
                                <PrivateRoute path="/ListProductTransfer" component={ListProductTransfer} />
                                <PrivateRoute path="/Reservation" component={Reservation} />
                                <PrivateRoute path="/ReservationDetail/:id" component={ReservationDetail} />
                                <PrivateRoute path="/ProductTransfer/:id" component={ProductTransfer} />
                            </Switch>
                            
                        </div>
                    }
                </Router>
            </Suspense>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
    languageData: state.languageData,
    loginData: state.loginData
});



export default connect(mapStateToProps, { AppsAction, LanguagesAction })(App)
