import React, { Component } from 'react'
import "../../../Assets/Css/newDashboard.css"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "bootstrap/scss/bootstrap.scss";
import { Menu } from 'antd';

class SupplierMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: 'light',
            current: '1',
        }

    }

    changeTheme = value => {
        this.setState({
            theme: value ? 'dark' : 'light',
        });
    };

    handleClick = e => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    };


    render() {


        const { loginData, loadingLogin, isAuth, errorMessage } = this.props.loginData
        const { SubMenu } = Menu;

        return (
            <>
                <div className='menu'>

                    {!loadingLogin && isAuth ?
                        <div className='companyInfo'>
                            <div className='avatar'>
                                <img src="/Assets/img/logo.svg" alt="icon" />
                            </div>
                            <div className='infos'>
                                <div className='companyName'>
                                    KHG GROUP
                                </div>
                                <div className='nameSurname'>
                                    {loginData.info.officalName} {loginData.info.officalSurname}
                                </div>

                                {
                                    loginData.info.approved === 1 ?
                                        <span className="badge bg-primary">Approved</span>
                                        :
                                        <span className="badge bg-warning">Waiting</span>
                                }

                            </div>
                        </div>
                        : ""}
                    <Menu
                        theme={this.state.theme}
                        onClick={this.handleClick}
                        style={{ width: 250 }}
                        defaultOpenKeys={['sub1']}
                        selectedKeys={[this.state.current]}
                        mode="inline"
                    >
                        <Menu.Item key="1" icon={<i className="glyph-icon iconsminds-shop"></i>}>
                            <Link to="/Dashboard">Dashboard</Link>
                        </Menu.Item>
                        <SubMenu key="sub2" icon={<i className="glyph-icon simple-icon-layers"></i>} title="Inventory">
                            <Menu.Item key="2">
                                <Link to="/ListProductTransfer">Transfer Products</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" icon={<i className="glyph-icon iconsminds-pantone"></i>} title="Bookings">
                            <Menu.Item key="3">
                                <Link to="/Reservation">Reservations</Link>
                            </Menu.Item>
                        </SubMenu>


                        <SubMenu key="sub8" icon={<i className="glyph-icon iconsminds-line-chart-1"></i>} title="Reports">
                        </SubMenu>


                    </Menu>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loginData: state.loginData,
    languageData: state.languageData
})



export default connect(mapStateToProps)(SupplierMenu)