import { PRODUCTDETAIL_GET, PRODUCTDETAIL_STEP, PRODUCT_TITLE } from '../../types'

const initialState = {
    productDetailData: [],
    steps: [],
    stepsLoading: true,
    loading: true,
    titleLoading: true,
    titleData: ""
}

function ProductTourReducer(state = initialState, action) {

    switch (action.type) {

        case PRODUCTDETAIL_GET:
            return {
                ...state,
                productDetailData: action.payload,
                loading: false

            }

        case PRODUCTDETAIL_STEP:
            return {
                ...state,
                steps: action.payload,
                stepsLoading: false
            }

        case PRODUCT_TITLE:
            return {
                ...state,
                titleData: action.payload,
                titleLoading: false
            }

        default:
            return state
    }

}

export default ProductTourReducer;