import { UPDATE_DOCUMENT_REQUEST_SENT, DOCUMENT_SET } from '../../types'

const initialState = {
    documentSetData: [],
    loadingSetData: false
}

function DocumentSetReducer(state = initialState, action) {

    switch (action.type) {

        case DOCUMENT_SET:
            return {
                ...state,
                documentSetData: action.payload,
                loadingSetData: false

            }
        case UPDATE_DOCUMENT_REQUEST_SENT:
            return {
                ...state,
                documentSetData: "",
                loadingSetData: true

            }
        default:
            return state
    }

}

export default DocumentSetReducer;