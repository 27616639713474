import { UPDATE_PAYMENT_REQUEST_SENT, PAYMENT_SET } from '../../types'

const initialState = {
    paymentSetData: [],
    loadingSetData: false
}

function PaymentSetReducer(state = initialState, action) {

    switch (action.type) {

        case PAYMENT_SET:
            return {
                ...state,
                paymentSetData: action.payload,
                loadingSetData: false

            }
        case UPDATE_PAYMENT_REQUEST_SENT:
            return {
                ...state,
                paymentSetData: "",
                loadingSetData: true

            }
        default:
            return state
    }

}

export default PaymentSetReducer;