import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineReducers } from 'redux'

import AppReducer from "./App/Reducer/AppReducer"
import LanguageReducer from "./App/Reducer/LanguageReducer"
import LoginReducer from "./Login/Reducer/LoginReducer"
import DashboardReducer from "./Dashboard/Reducer/DashboardReducer"

import AddProductReducer from "./AddProduct/Reducer/AddProductReducer"
import AddTransferReducer from "./AddProduct/Reducer/AddTransferReducer"

import FaqReducer from "./Home/Reducer/FaqReducer"
import ProfileReducer from "./Profile/Reducer/ProfileReducer"
import ProfileSetReducer from "./Profile/Reducer/ProfileSetReducer"

import ContactSetReducer from "./Profile/Reducer/ContactSetReducer"
import ContactReducer from "./Profile/Reducer/ContactReducer"
import PaymentSetReducer from "./Profile/Reducer/PaymentSetReducer"
import PaymentReducer from "./Profile/Reducer/PaymentReducer"

import SignupReducer from "./Signup/Reducer/SignupReducer"


import DocumentSetReducer from "./Profile/Reducer/DocumentSetReducer"

import ProductTourReducer from "./ProductTour/Reducer/ProductTourReducer"
import ProductTransferReducer from "./ProductTransfer/Reducer/ProductTransferReducer"








const rootReducer = combineReducers({
    appData: AppReducer,
    languageData: LanguageReducer,
    loginData: LoginReducer,
    dashboardData: DashboardReducer,
    addProductData: AddProductReducer,
    addTransferData: AddTransferReducer,
    productDetailData: ProductTourReducer,
    faqData: FaqReducer,
    profileData: ProfileReducer,
    profileSetData: ProfileSetReducer,
    contactData: ContactReducer,
    contactSetData: ContactSetReducer,
    paymentData: PaymentReducer,
    paymentSetData: PaymentSetReducer,

    documentSetData: DocumentSetReducer,
    signupData: SignupReducer,
    productTransferDetailData: ProductTransferReducer


});

const initalState = {}

const middleware = [thunk]
const store = createStore(rootReducer, initalState, composeWithDevTools(applyMiddleware(...middleware)))
export default store;