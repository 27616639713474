import { LANGUAGE_GET, LANGUAGE_ERROR } from '../../types';
import axios from "axios";




export const LanguagesAction = () => async dispatch => {
    try {


        const datas = {
            langCode: localStorage.getItem("langCode"),
        }


        const res = await axios.post(process.env.REACT_APP_API_ADDRESS + `/Language`, datas);
        dispatch({
            type: LANGUAGE_GET,
            payload: res.data
        });
    } catch (e) {
        dispatch({
            type: LANGUAGE_ERROR,
            payload: console.log(e)
        });
    }
};