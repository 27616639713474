import { SIGNUP_GET, SIGNUP_ERROR, SIGNUP_REQUEST_SENT, SIGNUP_SUCCESS } from '../../types'

const initialState = {
    signupData: [],
    loadingSignup: false,
    error: false,
    errorMessage: "",
    success: false

}

function SignupReducer(state = initialState, action) {

    switch (action.type) {

        case SIGNUP_GET:
            return {
                ...state,
                signupData: action.payload,
                loadingSignup: false,
                error: false,
                errorMessage: "",
                success: true
            }
        case SIGNUP_ERROR:
            return {
                ...state,
                signupData: action.payload,
                loadingSignup: false,
                error: true,
                errorMessage: "",
                success: false
            }
        case SIGNUP_REQUEST_SENT:
            return {
                ...state,
                signupData: "",
                loadingSignup: true,
                error: false,
                errorMessage: "",
                success: false
            }

        case SIGNUP_SUCCESS:
            return {
                ...state,
                signupData: action.payload,
                loadingSignup: false,
                error: false,
                errorMessage: "",
                success: true
            }



        default:
            return state
    }

}

export default SignupReducer;