import { PAYMENT_GET } from '../../types'

const initialState = {
    paymentData: [],
    loadingData: true
}

function PaymentReducer(state = initialState, action) {

    switch (action.type) {

        case PAYMENT_GET:
            return {
                ...state,
                paymentData: action.payload,
                loadingData: false

            }
        default:
            return state
    }

}

export default PaymentReducer;