import { UPDATE_PROFILE_REQUEST_SENT, PROFILE_SET } from '../../types'

const initialState = {
    profileSetData: [],
    loadingSetData: false
}

function ProfileSetReducer(state = initialState, action) {

    switch (action.type) {

        case PROFILE_SET:
            return {
                ...state,
                profileSetData: action.payload,
                loadingSetData: false

            }
        case UPDATE_PROFILE_REQUEST_SENT:
            return {
                ...state,
                profileSetData: "",
                loadingSetData: true

            }
        default:
            return state
    }

}

export default ProfileSetReducer;