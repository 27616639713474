import { LOGIN_GET, LOGIN_ERROR, LOGIN_CONNECTION_ERROR, LOGOUT, LOGIN_REQUEST_SENT } from '../../types';
import axios from "axios";
import { setAuthorizationToken } from "../../../Helpers/setAuthorizationToken"

export const LoginsAction = (userinfo) => async dispatch => {
    try {
        let datas = {
            username: userinfo.username,
            password: userinfo.password
        }



        dispatch({ type: LOGIN_REQUEST_SENT });
        const res = await axios.post(process.env.REACT_APP_API_ADDRESS + `/login`, datas);
        if (res.data.return) {

            const { token, refreshToken } = res.data

            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            setAuthorizationToken(token);
            dispatch({
                type: LOGIN_GET,
                payload: res.data
            });

        } else {
            dispatch({
                type: LOGIN_ERROR,
                payload: res.data
            });

        }

    } catch (e) {
        dispatch({
            type: LOGIN_CONNECTION_ERROR,
            payload: console.log(e)
        });
    }
};




export const logout = () => dispatch => {


    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    dispatch({
        type: LOGOUT,
        payload: "",
    });
}