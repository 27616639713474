import { CONTACT_GET } from '../../types'

const initialState = {
    contactData: [],
    loadingData: true
}

function ContactReducer(state = initialState, action) {

    switch (action.type) {

        case CONTACT_GET:
            return {
                ...state,
                contactData: action.payload,
                loadingData: false

            }
        default:
            return state
    }

}

export default ContactReducer;