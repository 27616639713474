import { LANGUAGE_GET } from '../../types'

const initialState = {
    languageData: [],
    loading: true
}

function LanguageReducer(state = initialState, action) {

    switch (action.type) {

        case LANGUAGE_GET:
            return {
                ...state,
                languageData: action.payload,
                loading: false

            }
        default:
            return state
    }

}

export default LanguageReducer;