import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SupplierHeader from "../components/SupplierHeader/Component/SupplierHeader";
import SupplierMenu from "../components/SupplierHeader/Component/SupplierMenu";
import 'antd/dist/antd.css';
const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem("token");
    let returns = false;
    if (token) {
        returns = true;

    } else {
        returns = false;
    }


    return (
        <Route {...rest} render={props => (
            returns ?
                <>
                    <SupplierHeader />
                    <div className="container-fluid mains">
                        <div className="left">
                            <SupplierMenu />
                        </div>
                        <div className="middle">
                            <Component {...props} />
                        </div>
                    </div>


                </> :
                <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        )}
        />
    );
};

export default PrivateRoute;