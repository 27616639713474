export const APP_GET = 'APP_GET';
export const APP_ERROR = 'APP_ERROR';

export const LANGUAGE_GET = 'LANGUAGE_GET';
export const LANGUAGE_ERROR = 'LANGUAGE_ERROR';

export const LOGIN_GET = 'LOGIN_GET';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_CONNECTION_ERROR = 'LOGIN_CONNECTION_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUEST_SENT = 'LOGIN_REQUEST_SENT';
export const IS_LOGIN = 'IS_LOGIN';


export const DASHBOARD_GET = 'DASHBOARD_GET';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';


export const ADDPRODUCT_GET = 'ADDPRODUCT_GET';
export const ADDPRODUCT_ERROR = 'ADDPRODUCT_ERROR';


export const ADDTRANSFER_GET = 'ADDTRANSFER_GET';
export const ADDTRANSFER_ERROR = 'ADDTRANSFER_ERROR';



export const PRODUCT_TITLE = 'PRODUCT_TITLE';

export const FAQ_GET = 'FAQ_GET';
export const FAQ_ERROR = 'FAQ_ERROR';

export const PROFILE_GET = 'PROFILE_GET';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const UPDATE_PROFILE_REQUEST_SENT = 'UPDATE_PROFILE_REQUEST_SENT';
export const PROFILE_SET = 'PROFILE_SET';
export const PROFILE_SET_ERROR = 'PROFILE_SET_ERROR';


export const CONTACT_GET = 'CONTACT_GET';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const UPDATE_CONTACT_REQUEST_SENT = 'UPDATE_CONTACT_REQUEST_SENT';
export const CONTACT_SET = 'CONTACT_SET';
export const CONTACT_SET_ERROR = 'CONTACT_SET_ERROR';

export const PAYMENT_GET = 'PAYMENT_GET';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const UPDATE_PAYMENT_REQUEST_SENT = 'UPDATE_PAYMENT_REQUEST_SENT';
export const PAYMENT_SET = 'PAYMENT_SET';
export const PAYMENT_SET_ERROR = 'PAYMENT_SET_ERROR';



export const DOCUMENT_GET = 'DOCUMENT_GET';
export const DOCUMENT_ERROR = 'DOCUMENT_ERROR';
export const UPDATE_DOCUMENT_REQUEST_SENT = 'UPDATE_DOCUMENT_REQUEST_SENT';
export const DOCUMENT_SET = 'DOCUMENT_SET';
export const DOCUMENT_SET_ERROR = 'DOCUMENT_SET_ERROR';




export const SIGNUP_GET = 'SIGNUP_GET';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_CONNECTION_ERROR = 'SIGNUP_CONNECTION_ERROR';
export const SIGNUP_REQUEST_SENT = 'SIGNUP_REQUEST_SENT'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'

export const PRODUCTDETAIL_GET = 'PRODUCTDETAIL_GET';
export const PRODUCTDETAIL_ERROR = 'PRODUCTDETAIL_ERROR';
export const PRODUCTDETAIL_LODING = 'PRODUCTDETAIL_LODING';
export const PRODUCTDETAIL_STEP = 'PRODUCTDETAIL_STEP';


export const PRODUCTDETAILTRANSFER_GET = 'PRODUCTDETAILTRANSFER_GET';
export const PRODUCTDETAILTRANSFER_ERROR = 'PRODUCTDETAILTRANSFER_ERROR';
export const PRODUCTDETAILTRANSFER_STEP = 'PRODUCTDETAILTRANSFER_STEP';
export const PRODUCTTRANSFER_TITLE = 'PRODUCTTRANSFER_TITLE';