import axios from 'axios';
import store from "../components/store"
import { LOGIN_GET, LOGOUT } from "../components/types"
import jwt_decode from "jwt-decode";

export const setAuthorizationToken = token => {

    let jwts = ""

    try {

        jwts = jwt_decode(token);

    } catch (e) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        console.log(e);
    }



    if (jwts === undefined && jwts === "undefined" && jwts === "") {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        store.dispatch({
            type: LOGOUT,
            payload: "",
        });
    }


    if (jwts === undefined && jwts === "undefined" && jwts === "") {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    axios.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem("token");
            if (accessToken) {
                config.headers["Authorization"] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        delete axios.defaults.headers.common["Authorization"];
        const originalRequest = error.config
        let refreshToken = localStorage.getItem("refreshToken");


        if (typeof error.response !== "undefined") {
            if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                return axios.post(process.env.REACT_APP_API_ADDRESS + `/RefreshToken`, { refreshToken: refreshToken })
                    .then((res) => {

                        if (res.status === 200 && res.data.return) {
                            localStorage.setItem("token", res.data.token);
                            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                            return axios(originalRequest);
                        } else {
                            localStorage.removeItem("token");
                            localStorage.removeItem("refreshToken");

                            store.dispatch({
                                type: LOGOUT,
                                payload: "",
                            });
                        }
                    });
            } else {
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");

                store.dispatch({
                    type: LOGOUT,
                    payload: "",
                });
                // window.location.reload();
            }
        } else {

        }

        return Promise.reject(error);
    });


}

export const setLanguageHeader = langCode => {
    axios.defaults.headers.common["clientLanguage"] = langCode;
}

export const setguid = guid => {

    if (guid) {
        axios.defaults.headers.common["guid"] = guid;
    } else
        delete axios.defaults.headers.common["guid"];
}

export const getRefreshPageTokenSet = () => {

    let refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken !== null) {
        return axios.post(process.env.REACT_APP_API_ADDRESS + `/RefreshToken`, { refreshToken: refreshToken })
            .then((res) => {
                if (res.status === 200) {

                    store.dispatch({
                        type: LOGIN_GET,
                        payload: res.data
                    });
                    localStorage.setItem("token", res.data.token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;

                }
            });
    } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        store.dispatch({
            type: LOGOUT,
            payload: "",
        });
    }

}