import { APP_GET } from '../../types'

const initialState = {
    appData: [],
    loading: true
}

function AppReducer(state = initialState, action) {

    switch (action.type) {

        case APP_GET:
            return {
                ...state,
                appData: action.payload,
                loading: false

            }
        default:
            return state
    }

}

export default AppReducer;