import { FAQ_GET } from '../../types'

const initialState = {
    faqData: [],
    loading: true
}

function FaqReducer(state = initialState, action) {

    switch (action.type) {

        case FAQ_GET:
            return {
                ...state,
                faqData: action.payload,
                loading: false

            }
        default:
            return state
    }

}

export default FaqReducer;