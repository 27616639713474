import { APP_GET, APP_ERROR } from '../../types';
import axios from "axios";
import detectBrowserLanguage from 'detect-browser-language'

import { osVersion, osName, fullBrowserVersion, browserName, mobileVendor, mobileModel, deviceType, getUA } from "react-device-detect";
import { setguid } from "../../../Helpers/setAuthorizationToken";

export const AppsAction = (guid = false) => async dispatch => {
    try {

        let datas = {
            guid: guid,
            language: detectBrowserLanguage(),
            osVersion: osVersion,
            osName: osName,
            fullBrowserVersion: fullBrowserVersion,
            browserName: browserName,
            mobileVendor: mobileVendor,
            mobileModel: mobileModel,
            deviceType: deviceType,
            getUA: getUA,

        }

        const res = await axios.post(process.env.REACT_APP_API_ADDRESS + `/checkUid`, datas);
        if (guid === false) {
            localStorage.setItem("guid", res.data.guid);
        } else if (res.data.flag === 2) {
            localStorage.setItem("guid", res.data.guid);
        }

        setguid(res.data.guid);

        dispatch({
            type: APP_GET,
            payload: res.data
        });
    } catch (e) {
        dispatch({
            type: APP_ERROR,
            payload: console.log(e)
        });
    }
};