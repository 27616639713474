import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/Component/App';
import reportWebVitals from './reportWebVitals';
import store from './components/store'
import { Provider } from 'react-redux'
import detectBrowserLanguage from 'detect-browser-language'


import { setAuthorizationToken, setLanguageHeader, setguid, getRefreshPageTokenSet } from "./Helpers/setAuthorizationToken";

/*
* GUID CHECK
*/


const guid = localStorage.getItem("guid");

if (guid) {
  setguid(guid)
}

/*
* LANGUAGE CHECK
*/


const langCode = localStorage.getItem("langCode");
if (!langCode) {
  localStorage.setItem("langCode", detectBrowserLanguage());
  setLanguageHeader(detectBrowserLanguage());
} else {
  setLanguageHeader(langCode);
}

/*
* TOKEN CHECK
*/


const token = localStorage.getItem("token");

if (token) {
  
  setAuthorizationToken(token);
  getRefreshPageTokenSet();
}






ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
