import { ADDTRANSFER_GET } from '../../types'

const initialState = {
    addTransferData: [],
    loadingAddTransfer: true,
    click:false
}

function AddTransferReducer(state = initialState, action) {

    switch (action.type) {

        case ADDTRANSFER_GET:
            return {
                ...state,
                addTransferData: action.payload,
                loadingAddTransfer: false,
                click:true

            }
        default:
            return state
    }

}

export default AddTransferReducer;