import { DASHBOARD_GET } from '../../types'

const initialState = {
    dashboardData: [],
    loading: true
}

function DashboardReducer(state = initialState, action) {

    switch (action.type) {

        case DASHBOARD_GET:
            return {
                ...state,
                dashboardData: action.payload,
                loading: false

            }
        default:
            return state
    }

}

export default DashboardReducer;