import { LOGIN_GET, LOGIN_ERROR, LOGIN_REQUEST_SENT, LOGOUT, IS_LOGIN } from '../../types'

const initialState = {
    loginData: [],
    loadingLogin: false,
    isAuth: false,
    error: false,
    errorMessage: "",

}

function LoginReducer(state = initialState, action) {

    switch (action.type) {

        case LOGIN_GET:
            return {
                ...state,
                loginData: action.payload,
                loadingLogin: false,
                isAuth: true,
                error: false,
                errorMessage: "",
            }
        case LOGIN_ERROR:
            return {
                ...state,
                loginData: action.payload,
                loadingLogin: false,
                isAuth: false,
                error: true,
                errorMessage: "01",
            }
        case LOGIN_REQUEST_SENT:
            return {
                ...state,
                loginData: "",
                loadingLogin: true,
                isAuth: false,
                error: false,
                errorMessage: "",
            }

        case LOGOUT:
            return {
                ...state,
                loginData: "",
                loadingLogin: false,
                isAuth: false,
                error: false,
                errorMessage: "",
            }

        case IS_LOGIN:
            return {
                ...state,
                loginData: "",
                loadingLogin: false,
                isAuth: true,
                error: false,
                errorMessage: "",
            }

        default:
            return state
    }

}

export default LoginReducer;